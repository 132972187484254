// $(document).ready(function () {

//   $('main').waypoint(function() {
//      $(this[0, 'element']).toggleClass('way');
//      },{offset: '-2%'}
//   );

// });

// Hamburguer menu
function hamburgerToggle() {
  this.classList.toggle('active');
}
document.querySelector('.header--hamburger').addEventListener('click', hamburgerToggle);


// Waypoints
var waypoint = new Waypoint({
  element: document.getElementById('waypoint01'),
  handler: function(direction) {
    document.getElementById('header').classList.toggle('waypoints');
  },
  offset: '-90%'
});
var waypoint = new Waypoint({
  element: document.getElementById('waypoint01'),
  handler: function(direction) {
    document.getElementById('header').classList.toggle('hide');
  },
  offset: '-5%'
});

var waypoint = new Waypoint({
  element: document.getElementById('waypoint01'),
  handler: function(direction) {document.getElementById('waypoint01').classList.add('way');},offset: '50%'
});
var waypoint = new Waypoint({
  element: document.getElementById('waypoint02'),
  handler: function(direction) {document.getElementById('waypoint02').classList.add('way');},offset: '50%'
});
var waypoint = new Waypoint({
  element: document.getElementById('waypoint03'),
  handler: function(direction) {document.getElementById('waypoint03').classList.add('way');},offset: '50%'
});
var waypoint = new Waypoint({
  element: document.getElementById('waypoint04'),
  handler: function(direction) {document.getElementById('waypoint04').classList.add('way');},offset: '50%'
});
var waypoint = new Waypoint({
  element: document.getElementById('waypoint05'),
  handler: function(direction) {document.getElementById('waypoint05').classList.add('way');},offset: '50%'
});

// Slider 5 col
var silder5 =  document.querySelector('.slider-col-5');
if (typeof(silder5) != 'undefined' && silder5 != null){
  var slider = tns({
    container: '.slider-col-5',
    slideBy: 1,
    gutter: 15,
    autoplay: false,
    fixedWidth: 250,
    controls: false,
    loop: false,
    navPosition: 'bottom',
    responsive: {
        0: {
            items:3,
            slideBy: 2
        },
        992: {
            items: 5
        }
    }
  });
}

// Slider 7 col
var silder7 =  document.querySelector('.slider-col-7');
if (typeof(silder7) != 'undefined' && silder7 != null){
  var slider = tns({
    container: '.slider-col-7',
    slideBy: 1,
    gutter: 15,
    autoplay: false,
    fixedWidth: 150,
    controls: false,
    loop: false,
    navPosition: 'bottom',
    responsive: {
        0: {
            items:2,
            slideBy: 2
        },
        576: {
            items: 3
        },
        992: {
            items: 7
        }
    }
  });
}

// Slider filtro
// var silderFiltro =  document.querySelector('.slider-filtro');
// if (typeof(silderFiltro) != 'undefined' && silderFiltro != null){
//   var slider = tns({
//     container: '.slider-filtro',
//     slideBy: 1,
//     items:4,
//     autoplay: false,
//     autoWidth: true,
//     nav:true,
//     controls: true,
//     mouseDrag: true,
//     loop: false,
//   });
// }
